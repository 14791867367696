@import './variable.scss';

.tgpp-home-tab-menu {

  .mat-tab-nav-bar.mat-primary.mat-background-primary .mat-ink-bar {
    background-color: #c00;
    height: 4px;
  }
}

.tgpp-initiate-review-reviewer-container {
  .review-participants {
    .mat-checkbox-layout {
      display: block !important;
    }
  }
}

.tgpp-new-review-container {
  mat-tab-group {
    mat-tab-body .mat-tab-body-content {
      max-height: calc(100vh - #{$header-height} - #{$menu-tab-height} - #{$footer-height} - 45px)
    }
  }
}

.tgpp-manage-review-list-container {
  .table-action .mat-form-field-wrapper {
    padding-bottom: 0px;
  }
  .mat-sort-header-container {
    display: flex;
    justify-content: center;
    font-size: 14px;
    color: black;

  }  
}

.search-box-field{  
  .mat-form-field-wrapper{
    padding-bottom: 0;
    margin: 0 !important;
  }
  .mat-form-field-infix {
    width: 230px;
  }  
}


.pending-review-container {
  .mat-sort-header-container {
    display: flex;
    justify-content: center;
    font-size: 14px;
    font-weight: bold;
    color: black;
  }
}


.closed-review-container {
  .mat-sort-header-container {
    display: flex;
    font-size: 14px;
    font-weight: bold;
    color: black;
  }
  
}


.completed-review-container {
  .mat-sort-header-container {
    display: flex;
    justify-content: center;
    font-size: 14px;
    font-weight: bold;
    color: black;
  }
}


.tgpp-need-help-container {
  .mat-dialog-content {
    max-height: 55vh;
    width: 500px;
  }
}


.my-profile-container {
  .tgpp-user-fields-readonly {
    .mat-form-field-appearance-outline .mat-form-field-outline {
      background-color: rgba(0, 0, 0, .04);
    }
  }
}


.user-registration-container {
  .tgpp-user-fields-readonly {
    .mat-form-field-appearance-outline .mat-form-field-outline {
      background-color: rgba(0, 0, 0, .04);
    }
  }
}


.tgpp-user-fields-readonly {
  .mat-form-field-appearance-outline .mat-form-field-outline {
    background-color: rgba(0, 0, 0, .04);
  }
}




@media print {
  .reviewguide-container {
    mat-tab-group.mat-tab-group>mat-tab-header.mat-tab-header {
      display: none;
    }
  }
}


.helpcenter-container {
  @import '../../app/feature/review-guidelines/static-content-styles.scss';

  a {
    cursor: pointer;
  }

}

.reviewguide-container {
  @import '../../app/feature/review-guidelines/static-content-styles.scss';
  a {
    cursor: pointer;
  }
}
