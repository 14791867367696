// @import "~bootstrap/dist/css/bootstrap.css";
@import 'common';

* {
  box-sizing: border-box;
  font-family: Toyota-regular, sans-serif;
}

$mobile-width: 994px;

@font-face {
  font-family: Toyota-book;
  font-weight: 200;
  font-style: normal;
  src: url("assets/fonts/Toyota/Toyota-book.eot"); /* IE9 Compat Modes */
  src: url("assets/fonts/Toyota/Toyota-book.woff") format("woff"),
    url("assets/fonts/Toyota/Toyota-book.eot?#iefix") format("embedded-opentype"),
    url("assets/fonts/Toyota/Toyota-book.ttf") format("truetype");
}

@font-face {
  font-family: Toyota-light;
  font-weight: 300;
  font-style: normal;
  src: url("assets/fonts/Toyota/Toyota-light.eot"); /* IE9 Compat Modes */
  src: url("assets/fonts/Toyota/Toyota-light.woff") format("woff"),
    url("assets/fonts/Toyota/Toyota-light.eot?#iefix") format("embedded-opentype"),
    url("assets/fonts/Toyota/Toyota-light.ttf") format("truetype");
}

@font-face {
  font-family: Toyota-regular;
  font-weight: 400;
  font-style: normal;
  src: url("assets/fonts/Toyota/Toyota-regular.eot"); /* IE9 Compat Modes */
  src: url("assets/fonts/Toyota/Toyota-regular.woff") format("woff"),
    url("assets/fonts/Toyota/Toyota-regular.eot?#iefix") format("embedded-opentype"),
    url("assets/fonts/Toyota/Toyota-regular.ttf") format("truetype");
}

@font-face {
  font-family: Toyota-semibold;
  font-weight: 600;
  font-style: normal;
  src: url("assets/fonts/Toyota/Toyota-semibold.eot"); /* IE9 Compat Modes */
  src: url("assets/fonts/Toyota/Toyota-semibold.woff") format("woff"),
    url("assets/fonts/Toyota/Toyota-semibold.eot?#iefix") format("embedded-opentype"),
    url("assets/fonts/Toyota/Toyota-semibold.ttf") format("truetype");
}

@font-face {
  font-family: Toyota-bold;
  font-weight: 700;
  font-style: normal;
  src: url("assets/fonts/Toyota/Toyota-bold.eot"); /* IE9 Compat Modes */
  src: url("assets/fonts/Toyota/Toyota-bold.woff") format("woff"),
    url("assets/fonts/Toyota/Toyota-bold.eot?#iefix") format("embedded-opentype"),
    url("assets/fonts/Toyota/Toyota-bold.ttf") format("truetype");
}

@font-face {
  font-family: Toyota-black;
  font-weight: 900;
  font-style: normal;
  src: url("assets/fonts/Toyota/Toyota-black.eot"); /* IE9 Compat Modes */
  src: url("assets/fonts/Toyota/Toyota-black.woff") format("woff"),
    url("assets/fonts/Toyota/Toyota-black.eot?#iefix") format("embedded-opentype"),
    url("assets/fonts/Toyota/Toyota-black.ttf") format("truetype");
}

body,
html {
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.medium-40 {
  font-size: 40px;
  font-weight: 600;
  line-height: 48px;
  margin: 0;
}

.medium-24 {
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  margin: 0;
}

.medium-20 {
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
  margin: 0;
}

.medium-16 {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  margin: 0;
}

.medium-12 {
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  margin: 0;
}

.regular-40 {
  font-size: 40px;
  font-weight: 400;
  line-height: 48px;
  margin: 0;
}

.regular-24 {
  font-size: 24px;
  font-weight: 400;
  margin: 0;
}

.regular-20 {
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  margin: 0;
}

.regular-16 {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin: 0;
}

.regular-12 {
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  margin: 0;
}

.dark-text-900 {
  color: rgb(14, 19, 25);
}
.dark-text-700 {
  color: rgba(14, 19, 25, 0.7);
}

.light-text-900 {
  color: rgb(255, 255, 255);
}
.light-text-700 {
  color: rgba(255, 255, 255, 0.7);
}

.brand-500 {
  color: rgb(204, 0, 0);
}

.text-center {
  text-align: center;
}
.text-top {
  text-align: right;
}
.text-left {
  text-align: left;
}

/* ============== LAYOUT ===============*/

.d-flex {
  display: flex;
}
.a-center {
  align-items: center;
}
.flex-1 {
  flex: 1;
  flex-basis: auto !important;
}
.j-center {
  justify-content: center;
}
.f-row {
  flex-direction: row;
}
.f-column {
  flex-direction: column;
}
.align-flex-start {
  align-items: flex-start;
}
.f-wrap {
  flex-wrap: wrap;
}
.w-100 {
  width: 100%;
}
.h-100 {
  height: 100%;
}
.float-right {
  float: right;
}

.col-1 {
  width: 8.33%;
}
.col-2 {
  width: 16.66%;
}
.col-3 {
  width: 25%;
}
.col-4 {
  width: 33.33%;
}
.col-5 {
  width: 41.66%;
}
.col-6 {
  width: 50%;
}
.col-7 {
  width: 58.33%;
}
.col-8 {
  width: 66.66%;
}
.col-9 {
  width: 75%;
}
.col-10 {
  width: 83.33%;
}
.col-11 {
  width: 91.66%;
}
.col-12 {
  width: 100%;
}

.mt-8 {
  margin-top: 8px;
}
.mt-12 {
  margin-top: 12px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-32 {
  margin-top: 32px;
}
.mt-40 {
  margin-top: 40px;
}
.mt-auto {
  margin-top: auto;
}

.mb-8 {
  margin-bottom: 8px;
}
.mb-12 {
  margin-bottom: 12px;
}
.mb-20 {
  margin-bottom: 20px !important;
}
.mb-32 {
  margin-bottom: 32px;
}
.mb-40 {
  margin-bottom: 40px;
}
.mb-auto {
  margin-bottom: auto;
}

.mr-8 {
  margin-right: 8px;
}
.mr-12 {
  margin-right: 12px;
}
.mr-20 {
  margin-right: 20px;
}
.mr-32 {
  margin-right: 32px;
}
.mr-40 {
  margin-right: 40px;
}
.mr-auto {
  margin-right: auto;
}

.ml-8 {
  margin-left: 8px;
}
.ml-12 {
  margin-left: 12px;
}
.ml-20 {
  margin-left: 20px;
}
.ml-32 {
  margin-left: 32px;
}
.ml-40 {
  margin-left: 40px;
}
.ml-auto {
  margin-left: auto;
}

/* ==========BORDERS============== */

.border-right-w-32 {
  border-right: 1px solid rgba(255, 255, 255, 0.32);
}

.border-left-w-32 {
  border-left: 1px solid rgba(255, 255, 255, 0.32);
}

.border-right-d-12 {
  border-right: 1px solid rgba(14, 19, 25, 0.12);
}

.border-left-d-12 {
  border-left: 1px solid rgba(14, 19, 25, 0.12);
}

.border-light {
  border: 1px solid rgba(255, 255, 255, 0.32);
}

.border-dark-shadow {
  box-shadow: 0 0 1px 0 rgba(14, 19, 25, 0.4), 0 2px 4px 0 rgba(14, 19, 25, 0.08);
}

.split-middle {
  border-right: 1px solid rgba(14, 19, 25, 0.12);
  border-left: 1px solid rgba(14, 19, 25, 0.12);
}
hr {
  border-color: rgba(255, 255, 255, 0.32);
  margin: 0px 27px;
  // margin: 300px 0px;
  // margin-bottom: 0;
}

.customheader {
  background-color: rgb(14, 19, 25);
  color: #fff;
  padding: 0 30px;
  position: fixed;
  margin: 0;
  width: 100%;
  z-index: 999999;
  border-bottom: 1px solid rgba(255, 255, 255, 0.32);
  top: 0;
}

// .brand-name {
//   text-decoration: none;
//   padding: 18px 20px;
//   font-weight: 600;
//   font-family: 'Toyota-Semibold';
// }

.links.dark {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}

.logo {
  width: 48px;
}

a {
  outline: none;
}

.links a {
  padding: 30px 0px;
  padding-bottom: 33px;
  margin: 0 20px;
  font-size: 16px;
  color: White;
  text-align: center;
  text-decoration: none;
  border-top: 3px solid rgb(14, 19, 25);
}

.light .links a {
  border-top: 3px solid white;
}

.customheader .title {
  color: rgba(14, 19, 25, 0.7);
  margin-left: 15px;
}

.customheader.dark .title {
  color: rgba(255, 255, 255, 0.7);
}

.customheader.dark .brand-name {
  color: white !important;
}

.customheader .active {
  border-bottom: 3px solid rgb(204, 0, 0) !important;
}


// .brand-name {
//   position: relative;
//   font-weight: 600;
// }

// .brand-name::after {
//   border-right: 1px solid rgba(255, 255, 255, 0.32);
//   content: "";
//   height: 30%;
//   position: absolute;
//   margin-left: 20px;
//   top: 13%;
//   right: 0px;
//   bottom: 0;
//   margin: auto;
// }

// .light .brand-name::after {
//   border-right: 1px solid rgba(14, 19, 25, 0.12);
// }

.customheader.light {
  background-color: $brand-color;
  color: #fff;
  box-shadow: 0px 4px 10px 0px #3603059e;
}

.links {
  align-items: center;
}

.customheader.light .links a {
  color: #fff;
}

section {
  position: relative;
  padding: 0 15%;
}

h2 {
  margin-top: 0;
  font-size: 40px;
  font-weight: 600;
  color: rgb(14, 19, 25);
  margin-bottom: 17px;
}

// h5 {
  // font-size: 20px;
  // margin: 0;
  // margin-bottom: 25px;
  // font-weight: 400;
  // line-height: 32px;
  // color: rgba(14, 19, 25, 0.7);
// }

h6 {
  font-size: 16px;
  margin: 10px 0;
  font-weight: 600;
  color: $brand-color
}

button,
.header-button {
  background-color: rgb(204, 0, 0);
  color: white;
  padding: 15px 44px 20px 44px;
  border: 0;
  box-shadow: none;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
  border-top: none !important;
}

// button:hover,
// button:focus,
// .header-button:hover,
// .header-button:focus {
  // background-color: #a60405;
  // outline: none;
// }

.header-button {
  border-radius: 2px;
  padding: 11px 28px 15px 28px !important;
  width: auto;
  color: #ffffff !important;
  font-size: 14px !important;
  font-weight: 600;
  text-align: center;
  background-color: rgb(204, 0, 0);
}

button:active,
.header-button:active {
  background-color: #700a0c;
  outline: none;
}

button.sm {
  padding-top: 13px 30px 15px;
}

button.btn-secondary {
  background-color: 255, 255, 255;
  border: 1px solid rgba(14, 19, 25, 0.16) !important;
  color: rgb(204, 0, 0);
  cursor: pointer;
}

button.btn-secondary:hover,
button.btn-secondary:focus {
  background-color: #eceded !important;
  border: 1px solid rgba(14, 19, 25, 0.12) !important;
  color: rgb(204, 0, 0);
  cursor: pointer;
}

button.btn-secondary:active {
  background-color: #e2e2e3 !important;
  border: 1px solid rgba(14, 19, 25, 0.12) !important;
  color: rgb(204, 0, 0);
  cursor: pointer;
}

button.btn-secondary-dark {
  background-color: rgb(14, 19, 25);
  border: 1px solid rgba(255, 255, 255, 0.32) !important;
  color: rgb(255, 255, 255);
  cursor: pointer;
  transition: all 0.2s ease;
}

button.btn-secondary-dark:hover,
button.btn-secondary-dark:focus {
  background-color: rgb(255, 255, 255) !important;
  border: 1px solid rgba(255, 255, 255, 0.32) !important;
  color: rgb(204, 0, 0);
  cursor: pointer;
}

button.btn-secondary-dark:active {
  background-color: rgba(255, 255, 255, 0.8) !important;
  border: 1px solid rgba(255, 255, 255, 0.32) !important;
  color: rgb(204, 0, 0);
  cursor: pointer;
}

button.btn-link {
  font-size: 16px;
  font-weight: 600;
  background: transparent;
  border: 0;
  padding: 10px 0;
  padding-bottom: 5px;
  border-radius: 0;
  border-bottom: 1px solid transparent;
}

button.btn-link:hover {
  border-bottom: 1px solid;
}

#brand a {
  text-decoration: none;
}

.arrow-right::after {
  content: "\203A";
  margin-left: 20px;
}

.btn-dark-secondary {
  background: transparent;
}

.btn-secondary {
  box-sizing: border-box;
  border: 1px solid rgba(14, 19, 25, 0.16);
  border-radius: 4px;
  background-color: #ffffff;
  color: rgb(204, 0, 0);
}

.bg-btn {
  height: 44px;
}

h3 {
  font-size: 24px;
  line-height: 32px;
  font-weight: 400;
  color: $brand-color;
}

h4 {
  color: $brand-color;
  margin-block-start: 0;
  margin-block-end: 0;
}


.reviews img {
  width: 60%;
}

.dd-icon {
  margin-right: 20px;
}

.icon-blue {
  width: 20px;
}

.tdrs-grey .screen {
  width: 25%;
  position: absolute;
  bottom: 0;
  right: 7%;
  z-index: 2;
}

.ipad-1 {
  width: 90%;
  position: absolute;
  top: -15%;
  right: -40%;
}

.iphone-1 {
  width: 25%;
  position: absolute;
  top: 40%;
  right: 30%;
}

.run-dealer-img {
  position: absolute;
  width: 50%;
  top: 0%;
  bottom: 0;
  left: -3%;
}

.tdrs-how h2 {
  margin-bottom: 12px;
}

.tdrs-how h3 {
  margin-bottom: 120px;
  margin-top: 0px;
}

.tdrs-how-step {
  width: 90%;
  margin: auto;
}

.tdrs-how-step-content h2 {
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  color: rgb(14, 19, 25);
  margin-bottom: 7px;
}

.tdrs-how-step-content h3 {
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  color: rgba(14, 19, 25, 0.7);
  margin-bottom: 25px;
}

.tdrs-how-step-content p {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: rgba(14, 19, 25, 0.7);
}

.middle-step {
  position: relative;
}

.middle-step::after,
.middle-step::before {
  content: "\2192";
  color: #fff;
  display: flex;
  display: inline-flex;
  width: 40px;
  height: 40px;
  position: absolute;
  justify-content: center;
  border-radius: 100px;
  background-color: rgb(204, 0, 0);
  align-items: baseline;
  padding-top: 6px;
  box-sizing: border-box;
  box-shadow: 0 4px 8px 0 rgba(14, 19, 25, 0.2);
}

.middle-step::after {
  right: -20px;
  top: 20%;
}
.middle-step::before {
  left: -20px;
  top: 20%;
}
.tdrs-how-step-content {
  max-width: 400px;
  margin: auto;
  padding: 0px 20px;
}

.tdrs-how {
  margin: 150px 0;
  flex-direction: column;
  text-align: center;
}

.run-dealer-person {
  position: absolute;
  bottom: 0;
  //margin: auto;
  left: 0%;
  //height: 100%;
}

.tdrs-dark {
  background-color: rgb(14, 19, 25);
  height: 80vh;
  display: flex;
  width: 100%;
}

.daily-dark {
  background-color: rgb(14, 19, 25);
  height: 100%;
  width: 100%;
  color: #fff;
  padding-top: 15%;
}

.daily-inventory-dark {
  background-color: rgb(14, 19, 25);
  height: 100%;
  width: 100%;
  color: #fff;
  // padding-top: 15%;
}

.tdrs-dark h2 {
  color: #fff;
}
.tdrs-dark h5 {
  color: #ffffffb3;
}

.daily-dark-header {
  margin-top: 10px;
  margin-bottom: 20px;
}

.tdrs-intro {
  color: rgb(14, 19, 25);
  padding: 100px 0px 300px;
}

.tdrs-grey {
  background: #f5f6f8;
  height: 100%;
  padding-top: 25%;
  padding-bottom: 20%;
  width: 100%;
  display: flex;
}

.tdrs-white {
  background: white;
  height: 70vh;
  width: 100%;
}

.daily-intro {
  background-color: rgb(14, 19, 25);
  color: white;
  padding: 200px 0px 125px;
}

.smartpath-intro {
  background-color: rgb(255, 255, 255);
  color: white;
  padding: 200px 0px 125px;
  padding-bottom: 350px;
}

.sp-shop {
  padding: 200px 10%;
  padding-bottom: 150px;
  text-align: left;
}

.dark {
  background-color: rgb(14, 19, 25);
  color: white;
}

.white {
  background-color: rgb(255, 255, 255);
  color: rgb(14, 19, 25);
}

.light-grey {
  background: #f5f6f8;
}

.spliter {
  border-right: 1px solid rgba(14, 19, 25, 0.12);
}

.tdrs-video {
  text-align: center;
  position: relative;
}

.tdrs-video .seamless {
  padding: 110px 0 110px 0;
  margin: 0px 15%;
  border-bottom: 1px solid rgba(14, 19, 25, 0.12);
}

.dark.spliter {
  border-right: 1px solid rgba(255, 255, 255, 0.12);
}

.video-still {
  margin-top: -10%;
  width: 80rem;
  height: 45.063rem;
  // width: 50%;
  // height: 50%;
  box-shadow: 0 80px 160px 0px rgba(0, 0, 0, 0.2);
}

.daily-intro h3 {
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 0;
}

.tdrs-pricing h1 {
  color: #fff;
  font-size: 40px;
  font-weight: 600;
  margin-bottom: 0;
}

.tdrs-pricing {
  text-align: center;
  height: auto;
  padding-top: 80px;
}

.footer-logo {
  width: 60px;
  margin-bottom: 20px;
}

.footer-price {
  padding: 4rem 0;
  width: 100%;
  padding-bottom: 0px;
}

.footer-summary {
  padding: 0 60px;
}

// .footer-content {
//   border-left: 1px solid rgba(255, 255, 255, 0.32);
// }

.shop-image-set {
  position: relative;
  height: 100%;
}
.shop-image-set .shop-1 {
  margin: auto;
  z-index: 10;
  width: 250px;
  position: absolute;
  left: 0;
  right: 0;
  top: -10px;
  border-radius: 40px;
  filter: drop-shadow(0 60px 120px rgba(0, 0, 0, 0.2));
  box-shadow: -5px 8px 15px 0 rgba(0, 0, 0, 0.2);
}

.shop-image-set .shop-2 {
  position: absolute;
  z-index: 1;
  left: 0px;
  width: 300px;
  top: 21%;
}

.shop-image-set .shop-3 {
  position: absolute;
  z-index: 11;
  width: 200px;
  //top: 60%;
  top: 55%;
  right: 13%;
  box-shadow: 0 40px 80px 0 rgba(0, 0, 0, 0.2);
}

.footer-price h2 {
  color: #fff;
  margin-bottom: 10px;
}

.footer-price h5 {
  color: #fff;
  font-weight: 400;
}

.footer-price p {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.7);
}

footer {
  //border-top: 1px solid rgba(255, 255, 255, 0.32);
  text-align: left;
  color: #fff;
}

footer .footer-inner {
  padding: 40px 0%;
}

footer .logo {
  width: 40px;
  margin-right: 20px;
}

#brand {
  transition: margin 0.3s ease;
}

.select {
  position: relative;
}
select::-ms-expand {
  display: none;
}
.select select {
  background: transparent;
  padding: 10px;
  padding-right: 30px;
  border: 0;
  color: #fff;
  font-size: 24px;
  border-bottom: 1px solid rgb(204, 0, 0);
  width: auto;
}

.select select option span {
  color: rgba(255, 255, 255, 0.7);
  font-size: 24px;
}

// .ss-dd-holder {
//   height: 650px;
//   width: 1000px;
//   // background-color: rgba(255, 255, 255, 0.08);
//   position: absolute;
//   top: 20%;
//   right: -15%;
//   right: -40%;
//   right: -25%;
//   right: -40%;
//   right: -60%;
//   top: 5%;
//   right: -30%;
//   width: 60%;
//   right: -50%;
//   position: relative;
//   height: 400px;
// }

.ss-dd-holder2 {
  width: 40%;
  max-width: 800px;
  background-color: rgba(255, 255, 255, 0.08);
  position: absolute;
  top: 5%;
  bottom: 0;
  margin: auto;
  right: 0%;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  box-shadow: 0 60px 120px 0 rgba(0, 0, 0, 0.2);
}

.screen-still {
  width: 222px;
  position: absolute;
  top: 7%;
  margin: auto;
  right: 30%;
  box-shadow: 0 40px 80px 0 rgba(0, 0, 0, 0.2);
}

.ss-dd-incentive {
  width: 18%;
  top: 7%;
  right: 24.5%;
  position: absolute;
}

.device-laptop {
  width: 800px;
  top: 0%;
  bottom: 0;
  margin: auto;
  left: 0%;
  position: absolute;
}

.covid-ready-container {
  padding: 150px 10%;
  text-align: center;
  padding-bottom: 0;
}

.covid-split {
  margin-top: 150px;
  margin-bottom: 70px;
}

.covid-split .inner {
  text-align: center;
  padding: 70px;
}

.covid-ready-container hr {
  margin-top: 150px;
}

.smartpath-split {
  margin-top: 130px;
  padding: 0px 15%;
  min-width: 120%;
}

.integration-splitter {
  border-right: 1px solid rgba(14, 19, 25, 0.12);
  border-left: 1px solid rgba(14, 19, 25, 0.12);
}

.dark.integration-splitter {
  border-right: 1px solid rgba(255, 255, 255, 0.32);
  border-left: 1px solid rgba(255, 255, 255, 0.32);
}

.smartpath-split .inner {
  text-align: center;
  padding: 30px;
}

.reviews {
  text-align: center;
}

.reviews .people img {
  width: 48px;
  margin: 20px;
}

img.middle-person {
  width: 100px !important;
}

.review-content {
  width: 650px;
  margin: 30px auto auto;
}

// .more-features {
//   padding-top: 0px;
//   padding-bottom: 200px;
//   text-align: center;
// }

.features {
  flex-wrap: wrap;
  margin-top: 90px;
  padding-bottom: 100px;
}

.feature-card {
  border-radius: 4px;
  padding: 30px;
  flex: 0 0 calc(33% - 23px);
  text-align: left;
  margin-bottom: 40px;
  min-height: 300px;
}

.feature-card:nth-child(3n + 2) {
  margin: 0px 40px;
  margin-bottom: 40px;
}

// .more-features .integrations {
//   margin-top: 100px;
// }

// .more-features .integrations .integration {
//   list-style: none;
//   color: rgba(255, 255, 255, 0.7);
//   font-size: 16px;
//   line-height: 24px;
//   padding: 20px 0px;
// }


.integration:nth-child(2) {
  padding: 20px !important;
}

.number-circle {
  height: 40px;
  width: 40px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(255, 255, 255, 0.32);
}

.intro-title {
  max-width: 700px;
}

.sp-signal,
.sp-manage {
  height: 100%;
  width: 100%;
  padding-top: 200px;
  padding-bottom: 200px;
}

.contact-container {
  width: 100%;
  // height: 100vh;
}

.contact-left {
  flex: 0 0 60%;
}

.contact-right {
  flex: 1;
  background-color: #f5f6f8;
  box-shadow: inset 1px 0 0 0 rgba(14, 19, 25, 0.12);
}

.contact-option-picker {
  margin-bottom: 30px;
}

.contact-info-split {
  border-top: 1px solid rgba(14, 19, 25, 0.12);
  border-bottom: 1px solid rgba(14, 19, 25, 0.12);
  padding: 30px 0;
  margin: 30px 0;
}

.contact-left-inner {
  padding: 100px;
  padding-top: 170px;
  margin-left: auto;
  width: 80%;
}

.contact-right-inner {
  padding: 80px;
  padding-top: 400px;
  margin-right: auto;
  width: 60%;
}

.contact-option-picker-inner {
  padding: 20px;
  border: 1px solid rgba(14, 19, 25, 0.12);
  border-radius: 4px;
  text-align: left;
  width: 50%;
  cursor: pointer;
}
.contact-option-picker-inner:nth-child(1) {
  margin-right: 15px;
}

.contact-option-picker-inner.selected {
  border: 2px solid rgb(13, 106, 255);
}

.contact-inline-first {
  margin-right: 20px;
}

input {
  box-sizing: border-box;
  border: 1px solid rgba(14, 19, 25, 0.16);
  color: rgba(14, 19, 25, 0.8);
  border-radius: 4px;
  background-color: #ffffff;
  padding: 16px 12px;
  width: 100%;
  font-size: 16px;
  height: 25px;
}

textarea {
  width: 100%;
  resize: none;
  font-size: 16px;
  border: 1px solid rgba(14, 19, 25, 0.16);
  border-radius: 4px;
  padding: 12px;
  color: rgba(14, 19, 25, 0.8);
}

input:focus,
textarea:focus {
  outline: none;
  border: 1px solid rgb(13, 106, 255);
  box-shadow: 0 0 8px 0 rgba(13, 106, 255, 0.6);
}

input.error {
  outline: none;
  border: 1px solid rgb(204, 0, 0);
  background-color: rgba(204, 0, 0, 0.08);
}

input::placeholder {
  color: rgb(14, 19, 25);
}

select {
  border: 1px solid rgba(14, 19, 25, 0.16);
  padding: 16px 12px;
  border-radius: 4px;
  font-size: 16px;
  color: rgba(14, 19, 25, 0.7);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

select:after {
  content: "<>";
  font: 17px "Consolas", monospace;
  color: #333;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  right: 11px;
  /*Adjust for position however you want*/

  top: 18px;
  padding: 0 0 2px;
  border-bottom: 1px solid #999;
  /*left line */

  position: absolute;
  pointer-events: none;
}

.selectdiv,
.select {
  position: relative;
}

.selectdiv:after,
.select:after {
  content: "\276F";
  color: #333;
  font-size: 12px;
  transform: rotate(90deg);
  right: 15px;
  top: 22px;
  padding: 0 0 2px;
  position: absolute;
  pointer-events: none;
}

.selectdiv select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.selectdiv select:focus {
  outline: none;
  border: 1px solid rgb(13, 106, 255);
  box-shadow: 0 0 8px 0 rgba(13, 106, 255, 0.6);
}

.select:after {
  content: "\276F";
  color: #fff;
  font-size: 16px;
  transform: translateX(-20px) rotate(90deg);
  right: 0px;
  display: inline-block;
  position: static;
}

.footer-chevron {
  background: rgba(255, 255, 255, 0.08);
  width: 40px;
  height: 40px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  color: #fff;
  justify-content: center;
}

.footer-chevron:hover {
  background: #c00;
}

.footer-chevron span {
  margin-top: -5px;
}

footer a {
  text-decoration: none;
}

.FAQ-container {
  width: 100%;
  max-width: 700px;
  margin: 170px auto 0;
  padding: 30px;
}

.FAQ-container .contact-option-picker {
  margin-top: 57px;
}

.tabs {
  border-radius: 8px;
  overflow: hidden;
  margin-top: 50px;
}

.tabs input {
  opacity: 0;
  z-index: -1;
}

.tab {
  width: 100%;
  overflow: hidden;
}

.tab-label {
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  // font-family: "Toyota";
  cursor: pointer;
  padding: 25px 0;
  border-top: 1px solid rgba(14, 19, 25, 0.12);
  /* Icon */
}

.tab-label::after {
  content: "\276F";
  width: 15px;
  height: 15px;
  text-align: center;
  transition: all 0.35s;
  font-size: 10px;
  transform-origin: center;
}

.width-60 {
  width: 60px;
}

.tab-content {
  max-height: 0;
  padding: 0;
  color: rgba(14, 19, 25, 0.7);
  line-height: 24px;
  background: white;
  transition: all 0.65s ease;
}
.tab-close {
  display: flex;
  justify-content: flex-end;
  padding: 1em;
  font-size: 0.75em;
  background: #2c3e50;
  cursor: pointer;
}
.tab-close:hover {
  background: #fff;
}

input:checked + .tab-label {
  background: #fff;
}
input:checked + .tab-label::after {
  transform-origin: center center;
  transform: rotate(90deg);
}
input:checked ~ .tab-content {
  max-height: 100vh;
  padding: 1em 0;
}

@media screen and (max-width: 1692px) {
  .device-laptop {
    left: -10%;
  }
  .shop-image-set .shop-2 {
    left: -36px;
    width: 300px;
    top: 21%;
  }
  .shop-image-set .shop-3 {
    width: 200px;
    top: 53%;
    right: 8%;
  }
}

@media screen and (max-width: 1548px) {
  .contact-right-inner {
    width: 80%;
  }

  .contact-left-inner {
    width: 100%;
  }

  .ipad-1 {
    top: 0;
  }


  .screen-still {
    top: 21.8%;
    right: 29%;
  }
  .shop-image-set .shop-2 {
    left: -67px;
    top: 18%;
  }
  .shop-image-set .shop-3 {
    top: 48%;
  }
}

@media screen and (max-width: 1444px) {
  .ss-dd-holder2 {
    width: 45%;
    right: -10%;
  }

  .shop-image-set .shop-2 {
    left: -87px;
    top: 16%;
  }
  .shop-image-set .shop-3 {
    top: 43%;
  }
}

@media screen and (max-width: 1350px) {
  section,
  .smartpath-split,
  .sp-shop,
  .covid-ready-container,
  footer {
    padding-right: 5%;
    padding-left: 5%;
  }


  .device-laptop {
    left: -20%;
  }

  .shop-image-set .shop-2 {
    left: -87px;
    top: 16%;
  }
  .shop-image-set .shop-3 {
    top: 43%;
  }
  .smartpath-split {
    // margin-top: 130px;
    // padding: 0px 15%;
    min-width: 112%;
  }
}

@media screen and (max-width: 1280px) {
  .run-dealer-img {
    position: relative;
    width: 140%;
    margin-top: -450px;
    left: -50%;
    bottom: unset;
    top: -60%;
  }

  .ss-dd-holder2 {
    width: 55%;
    right: -20%;
  }

  .screen-still {
    right: 15%;
    top: 12%;
  }

  .shop-image-set .shop-2 {
    left: -39px;
    top: 20%;
  }
  .shop-image-set .shop-3 {
    top: 50%;
  }

  .video-still {
    margin-top: -16%;
    // width: 80rem;
    // height: 45.063rem;
    width: 70%;
    height: 70%;
    box-shadow: 0 80px 160px 0px rgba(0, 0, 0, 0.2);
  }
  .smartpath-split {
    min-width: 112%;
  }
}

@media screen and (max-width: 1219px) {
  .feature-card {
    flex: 0 0 calc(33% - 24px);
  }
}

@media screen and (max-width: 1158px) {
  .contact-right-inner {
    width: 100%;
    padding-right: 50px;
    padding-left: 50px;
  }

  .device-laptop {
    left: -30%;
  }

  .contact-left-inner {
    width: 100%;
    padding: 50px;
    padding-top: 170px;
  }


  .ss-dd-incentive {
    width: 30%;
    right: 10%;
  }
  .smartpath-split {
    min-width: auto;
  }
}

@media screen and (max-width: 1092px) {
  section.tdrs-pricing {
    padding-right: 0;
    padding-left: 0;
  }

  .device-laptop {
    left: -30%;
    width: 70%;
    top: -10%;
  }

  .ipad-1 {
    top: 15%;
  }

  .tdrs-smartpath-intro .col-4 {
    width: 50%;
  }

  .ss-dd-incentive {
    right: 30%;
    width: 20%;
  }

  .shop-image-set .shop-2 {
    left: -58px;
    top: 16%;
  }
  .shop-image-set .shop-3 {
    top: 44%;
  }
}

@media screen and (max-width: 985px) {
  .footer-summary {
    padding: 0px 40px;
  }

  .ss-dd-incentive {
    right: 20%;
  }


  .shop-image-set .shop-1 {
    top: 20%;
    width: 200px;
  }

  .ss-dd-holder2 {
    width: 70%;
    right: -30%;
  }

  .shop-image-set .shop-2 {
    left: -48px;
    top: 34%;
    width: 54%;
  }
  .shop-image-set .shop-3 {
    top: 52%;
    width: 36%;
  }
}

@media screen and (max-width: 887px) {
  .feature-card {
    flex: 0 0 calc(50% - 25px);
  }

  .feature-card:nth-child(3n + 2) {
    margin: unset;
    margin-bottom: 40px;
  }

  .feature-card:nth-child(odd) {
    margin-right: 40px;
  }

  .screen-still {
    right: 12%;
    top: 18%;
  }
}

@media screen and (max-width: 863px) {
  .contact-right-inner {
    padding-right: 24px;
    padding-left: 24px;
  }

  .contact-left-inner {
    padding: 24px;
    padding-top: 170px;
  }
}

@media screen and (max-width: 768px) {
  .dark .header-button {
    color: rgb(255, 255, 255) !important;
  }

  .links {
    align-items: flex-end;
  }



  .ss-dd-incentive {
    width: 40%;
    left: 15%;
    top: 5%;
  }

  .regular-24 {
    font-size: 20px;
    line-height: 32px;
  }

  footer .regular-24 {
    font-size: 16px;
  }

  footer .medium-24 {
    font-size: 16px;
  }

  footer {
    border-top: 1px solid rgba(255, 255, 255, 0.32);
    flex-direction: column;
    text-align: center;
  }

  footer a {
    display: block;
  }

  footer h4 {
    margin: auto !important;
  }

  .footer-chevron {
    margin: auto;
    //margin-top: 30px;
    color: #fff;
    margin-left: 15px;
  }

  .dark.integration-splitter,
  .integration-splitter {
    border-right: none;
    border-left: none;
  }

  .contact-left-inner {
    padding-top: 200px;
  }

  .contact-container {
    flex-direction: column;
  }

  .contact-header {
    text-align: center;
  }

  .contact-left {
    padding-bottom: 90px;
  }

  .contact-right-inner {
    padding-top: 71px !important;
  }

  .col-4 {
    width: 100% !important;
  }

  // .customheader {
  //   flex-direction: column;
  //   padding: 0;
  // }

  .brand {
    // margin: 16px 24px;
    width: calc(100% - 48px);
  }

  // .brand-name {
  //   margin: 0 16px !important;
  //   padding: 0;
  // }

  // .customheader .links {
  //   width: 100%;
  //   border-top: 1px solid rgba(14, 19, 25, 0.12);
  // }

  // .customheader .links a {
  //   flex: 1;
  //   margin: 0;
  //   padding: 20px 0px !important;
  //   padding-bottom: 33px;
  //   line-height: inherit !important;
  //   margin: 0 5px !important;
  //   text-align: center;
  //   min-width: fit-content !important;
  // }

  // .customheader .links a:nth-child(1) {
  //   padding: 18px 18px 18px 20px;
  //   white-space: nowrap;
  // }
  // .customheader .links a:nth-child(3) {
  //   padding: 18px 20px 18px 18px !important;
  // }
  // .customheader .links a:nth-child(2) {
  //   padding: 18px 18px;
  //   white-space: nowrap;
  // }

  // .customheader section {
  //   padding: 0px;
  // }



  .tdrs-intro {
    padding-bottom: 120px !important;
  }

  .intro-title {
    margin: 0px 30px;
  }

  .video-still {
    width: 100%;
    height: 100%;
    box-shadow: none;
    padding-top: 25px;
  }

  .tdrs-video .seamless {
    padding: 36px 30px;
    margin: 0;
    padding-bottom: 40px;
  }

  .medium-40 {
    font-size: 32px !important;
    line-height: 40px;
  }  

  .regular-40 {
    font-size: 32px;
    line-height: 40px;
  }

  .tdrs-smartpath-text {
    order: 1;
    text-align: center;
    padding: 30px;
    padding-top: 60px;
  }
  .tdrs-smartpath-images {
    position: relative;
  }

  .tdrs-grey {
    flex-direction: column;
  }

  .ipad-1 {
    position: relative;
    width: 900px;
    right: -30%;
    top: -40%;
  }

  .tdrs-how-step-content {
    max-width: 100%;
    width: 400px;
    padding: 0 30px;
  }

  .tdrs-smartpath-intro {
    padding-top: 0px;
  }

  .iphone-1 {
    width: 300px;
    left: 10%;
    top: 40%;
    right: unset;
  }

  .tdrs-daily-intro {
    height: 100%;
  }

  .tdrs-daily-intro-inner {
    flex-direction: column;
  }

  .tdrs-daily-intro-images {
    flex-direction: column;
    width: 100%;
    position: relative;
  }

  .tdrs-daily-intro-text {
    width: 100%;
    margin-top: 100px;
    text-align: center;
    padding: 0 30px;
    margin-bottom: 80px;
  }

  .run-dealer-img {
    position: relative;
    width: 140%;
    padding-bottom: 60px;
    margin-top: -50px;
    left: unset;
    top: 0;
  }

  .run-dealer-person {
    width: 60%;
    bottom: -10%;
    //height: 100%;
  }

  .tdrs-grey .screen {
    width: 50%;
  }

  .tdrs-how {
    margin: 60px 0px;
  }

  .tdrs-how h3 {
    margin-bottom: 50px;
  }

  .how-it-works {
    flex-direction: column;
  }

  .middle-step {
    margin: 150px 0px;
    display: flex;
    flex-direction: column;
  }

  .middle-step::after,
  .middle-step::before {
    content: "↓";
  }

  .middle-step::before {
    top: -90px;
    right: 0;
    left: 0;
    margin: auto;
  }

  .middle-step::after {
    bottom: -90px;
    right: 0;
    left: 0;
    margin: auto;
    top: unset;
  }

  .footer-summary {
    padding: 0;
  }

  // .footer-content {
  //   margin-top: 80px;
  //   padding-top: 80px;
  //   border-top: 1px solid rgba(255, 255, 255, 0.32);
  //   border-left: none;
  // }

  .footer-price {
    flex-direction: column;
    padding: 70px 30px;
  }

  .select select {
    font-size: 16px;
  }
  .footer-price .col-4 {
    width: 100%;
  }

  // .brand-name::after {
  //   display: none;
  // }

  .smartpath-split {
    flex-direction: column;
    padding: 0 30px;
    margin-top: 70px;
  }

  .smartpath-split .inner {
    flex-direction: column;
    padding: 22px 0px;
  }

  .split-middle {
    border: 0;
    border-top: 1px solid rgba(14, 19, 25, 0.12);
    border-bottom: 1px solid rgba(14, 19, 25, 0.12);
  }

  .smartpath-intro {
    padding-bottom: 80px;
  }

  .sp-shop,
  .covid-ready-container {
    margin: 0px;
    margin-top: 0px;
    padding: 0 27px;
    padding-top: 70px;
    flex-direction: column;
    padding-bottom: 80px;
  }

  .sp-shop .col-6 {
    width: 100%;
  }

  .shop-image-set .shop-1 {
    position: relative;
    width: 300px;
    border-radius: 10px;
  }

  .shop-image-set .shop-2 {
    width: 350px;
    left: -20px;
    top: 27%;
  }

  .shop-image-set .shop-3 {
    width: 300px;
    top: 56%;
    right: 3%;
  }

  .smartpath-save-content {
    margin-top: 100px;
  }

  .dd-icon img {
    width: 40px;
  }

  .smartpath-save-content .col-10 {
    width: 100%;
  }

  .mobile-order-1 {
    order: 1;
  }

  .mobile-order-0 {
    order: 0;
  }


  .mobile-content {
    padding: 0 27px;
    margin-top: 80px;
    width: 100%;
  }

  .ss-dd-holder2 {
    position: relative;
    width: 80%;
    right: -30%;
    margin-top: 200px;
  }

  .sp-signal {
    display: flex;
    flex-direction: column;
    padding-top: 0;
    padding-bottom: 70px;
  }

  .sp-signal hr {
    display: none;
  }

  .sp-signal .col-7 {
    width: 100%;
  }

  .sp-manage {
    flex-direction: column;
    padding-top: 70px;
    padding-bottom: 70px;
  }

  .sp-manage .col-6 {
    width: 100%;
  }

  .device-laptop {
    position: relative;
    width: 90%;
    margin-top: 50px;
    left: 0;
  }

  .sp-manage .col-10 {
    width: 100%;
  }

  .daily-inventory-dark .icon-blue {
    width: 20px;
  }

  .sp-manage hr {
    display: none;
  }

  .covid-ready-container .regular-40 {
    font-size: 16px;
  }

  .covid-split {
    flex-direction: column;
    margin-top: 50px;
  }

  .covid-split .inner {
    padding: 0;
  }

  .spliter {
    border: 0;
    padding-bottom: 60px !important;
    margin-bottom: 60px;
    border-bottom: 1px solid rgba(14, 19, 25, 0.12);
  }

  .review-content {
    width: 100%;
    color: rgba(14, 19, 25, 0.7);
  }

  .covid-ready-container hr {
    margin-top: 50px;
  }

  // .more-features {
  //   padding-top: 0;
  // }

  .features {
    padding-top: 50px;
    padding-bottom: 50px;
    margin-top: 0;
    padding-left: 27px;
    flex-wrap: nowrap;
    overflow: scroll;
  }
  .feature-card:nth-child(3n + 2) {
    margin: unset;
  }
  .feature-card {
    margin: 0;
    flex: 0 0 calc(65% - 54px);
    margin-right: 17px !important;
  }

  // .more-features .integrations {
  //   flex-direction: column;
  //   margin-top: 20px;
  // }

  .daily-dark {
    flex-direction: column;
    display: flex;
  }

  section {
    padding: 0;
  }

  .screen-still {
    width: 160px !important;
    right: 57%;
    top: 1.7%;
  }

  .light .links a {
    border-top: none;
    border-bottom: 3px solid white;
  }

  .dark.spliter {
    border-right: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.32);
  }

  .dark.integration-splitter {
    border-top: 1px solid rgba(255, 255, 255, 0.32);
    border-bottom: 1px solid rgba(255, 255, 255, 0.32);
  }

  .integration-splitter {
    border-top: 1px solid rgba(14, 19, 25, 0.12);
    border-bottom: 1px solid rgba(14, 19, 25, 0.12);
  }

  // .more-features {
  //   padding-bottom: 70px;
  //   padding-top: 50px !important;
  // }

  .integration {
    padding: 30px 0px;
    margin: 0px 28px;
  }

  .customheader .active {
    border-top: none !important;
    border-bottom: 3px solid rgb(204, 0, 0) !important;
  }

  .mobile-content .col-10 {
    width: 100%;
  }

  .header-button.dark {
    background: transparent;
  }

  .header-button {
    background: transparent;
    color: rgb(204, 0, 0) !important;
  }
  .header-button .active,
  .header-button:hover,
  .header-button:focus {
    background: transparent;
    color: rgb(14, 19, 25) !important;
  }
}

@media screen and (max-width: 650px) {
  .feature-card {
    flex: 0 0 calc(75% - 54px);
  }

  .shop-image-set .shop-1 {
    position: relative;
    width: 250px;
    border-radius: 10px;
  }

  .shop-image-set .shop-2 {
    width: 350px;
    left: -50px;
    top: 21%;
  }

  .shop-image-set .shop-3 {
    width: 250px;
    right: 5%;
  }

  .screen-still {
    width: 50%;
  }

  .video-still {
    padding-top: 0px;
  }
}
@media screen and (width: 1600px) {
  .screen-still {
    top: 14%;
  }
}
@media screen and (max-width: 550px) {
  .contact-option-picker {
    flex-direction: column;
  }

  .ss-dd-incentive {
    top: 0;
    left: 10%;
  }


  .contact-option-picker-inner:nth-child(1) {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .contact-option-picker-inner {
    width: 100%;
    min-height: 165px;
  }

  .contact-inline {
    flex-direction: column;
    margin: 12px 0 !important;
  }

  .contact-inline-first {
    flex-direction: column;
    margin-right: 0;
    margin-bottom: 12px;
  }
}

@media screen and (max-width: 466px) {
  .tdrs-grey .screen {
    width: 50%;
  }

  .ss-dd-holder2 {
    position: relative;
    width: 100%;
    right: -30%;
    margin-top: 100px;
  }

  .feature-card {
    flex: 0 0 calc(100% - 54px);
  }

  .iphone-1 {
    width: 200px;
    left: -10%;
    right: unset;
  }

  .ipad-1 {
    position: relative;
    width: 600px;
    right: -10px;
  }

  .shop-image-set .shop-1 {
    position: relative;
    width: 150px;
    border-radius: 10px;
  }

  .shop-image-set .shop-2 {
    width: 150px;
    left: 2px;
    top: 32%;
  }

  .shop-image-set .shop-3 {
    width: 140px;
    // right: 5%;
    top: 58%;
    right: 3%;
  }
}

// TGPP Global Styles

.mat-drawer-container {
  width: 100%;
  height: calc(100vh - 115px);
}

.mat-drawer-inner-container {
  background-color: #E8EAED;
  overflow: hidden !important;
  width: 220px !important;
}

.mat-drawer-content {
  padding: 0 2.5rem;
}

.tgpp-comments-line-break {
  word-break: break-word;
  white-space: pre-line;
}

.tgpp-comments-intent {
  text-indent: 20px;
}

.tgpp-round-details-conatiner {
  padding: 0 15px 5px 10px;
}

.tgpp-anchor-style {
  cursor: pointer;
  text-decoration: underline;
}

::-webkit-scrollbar{background-color:#fff;width:16px}
::-webkit-scrollbar-track{background-color:#fff}
::-webkit-scrollbar-button{display:none}.scrollbar::-webkit-scrollbar{background-color:#fff;width:16px}
::-webkit-scrollbar-thumb{background-color:#DADCE0;border-radius:16px;border:4px solid #fff}