.Arial_Black_Normal_08px {
	// font-family: Arial, Helvetica, sans-serif;
	font-size: 8px;
}
.Arial_Black_Normal_08px a:link {
	color: #000000;
}
.Arial_Black_Normal_08px a:hover {
	color: #c00;
}
.Arial_Black_Normal_08px a:visited {
	color: #666666;
}
.Arial_Black_Normal_09px {
	// font-family: Arial, Helvetica, sans-serif;
	font-size: 9px;
}
.Arial_Black_Normal_09px a:link {
	color: #000000;
}
.Arial_Black_Normal_09px a:hover {
	color: #c00;
}
.Arial_Black_Normal_09px a:visited {
	color: #666666;
}
.Arial_Black_Normal_10px {
	// font-family: Arial, Helvetica, sans-serif;
	font-size: 10px;
}
.Arial_Black_Normal_10px a:link {
	color: #000000;
}
.Arial_Black_Normal_10px a:hover {
	color: #c00;
}
.Arial_Black_Normal_10px a:visited {
	color: #666666;
}
.Arial_Black_Normal_11px {
	// font-family: Arial, Helvetica, sans-serif;
	font-size: 11px;
}
.Arial_Black_Normal_11px a:link {
	color: #000000;
}
.Arial_Black_Normal_11px a:hover {
	color: #c00;
}
.Arial_Black_Normal_11px a:visited {
	color: #666666;
}
.Arial_Black_Normal_12px {
	// font-family: Arial, Helvetica, sans-serif;
	font-size: 12px;
}
.Arial_Black_Normal_12px a:link {
	color: #000000;
}
.Arial_Black_Normal_12px a:hover {
	color: #c00;
}
.Arial_Black_Normal_12px a:visited {
	color: #666666;
}
.Arial_Black_Normal_14px {
	// font-family: Arial, Helvetica, sans-serif;
	font-size: 14px;
}
.Arial_Black_Normal_14px a:link {
	color: #000000;
}
.Arial_Black_Normal_14px a:hover {
	color: #c00;
}
.Arial_Black_Normal_14px a:visited {
	color: #666666;
}
.Arial_Black_Normal_16px {
	// font-family: Arial, Helvetica, sans-serif;
	font-size: 16px;
}
.Arial_Black_Normal_16px a:link {
	color: #000000;
}
.Arial_Black_Normal_16px a:hover {
	color: #c00;
}
.Arial_Black_Normal_16px a:visited {
	color: #666666;
}
.Arial_Black_Bold_08px {
	// font-family: Arial, Helvetica, sans-serif;
	font-size: 8px;
	font-weight: bold;
	color: #000000;
}
.Arial_Black_Bold_08px a:link {
	color: #000000;
}
.Arial_Black_Bold_08px a:hover {
	color: #c00;
}
.Arial_Black_Bold_08px a:visited {
	color: #666666;
}
.Arial_Black_Bold_09px {
	// font-family: Arial, Helvetica, sans-serif;
	font-size: 9px;
	font-weight: bold;
	color: #000000;
}
.Arial_Black_Bold_09px a:link {
	color: #000000;
}
.Arial_Black_Bold_09px a:hover {
	color: #c00;
}
.Arial_Black_Bold_09px a:visited {
	color: #666666;
}
.Arial_Black_Bold_10px {
	// font-family: Arial, Helvetica, sans-serif;
	font-size: 10px;
	font-weight: bold;
	color: #000000;
}
.Arial_Black_Bold_10px a:link {
	color: #000000;
}
.Arial_Black_Bold_10px a:hover {
	color: #c00;
}
.Arial_Black_Bold_10px a:visited {
	color: #666666;
}
.Arial_Black_Bold_11px {
	// font-family: Arial, Helvetica, sans-serif;
	font-size: 11px;
	font-weight: bold;
	color: #000000;
}
.Arial_Black_Bold_11px a:link {
	color: #000000;
}
.Arial_Black_Bold_11px a:hover {
	color: #c00;
}
.Arial_Black_Bold_11px a:visited {
	color: #666666;
}
.Arial_Black_Bold_12px {
	// font-family: Arial, Helvetica, sans-serif;
	font-size: 12px;
	font-weight: bold;
	color: #000000;
}
.Arial_Black_Bold_12px a:link {
	color: #000000;
}
.Arial_Black_Bold_12px a:hover {
	color: #c00;
}
.Arial_Black_Bold_12px a:visited {
	color: #666666;
}
.Arial_Black_Bold_14px {
	// font-family: Arial, Helvetica, sans-serif;
	font-size: 14px;
	font-weight: bold;
	color: #000000;
}
.Arial_Black_Bold_14px a:link {
	color: #000000;
}
.Arial_Black_Bold_14px a:hover {
	color: #c00;
}
.Arial_Black_Bold_14px a:visited {
	color: #666666;
}
.Arial_Black_Bold_16px {
	// font-family: Arial, Helvetica, sans-serif;
	font-size: 16px;
	font-weight: bold;
	color: #000000;
}
.Arial_Black_Bold_16px a:link {
	color: #000000;
}
.Arial_Black_Bold_16px a:hover {
	color: #c00;
}
.Arial_Black_Bold_16px a:visited {
	color: #666666;
}
.Arial_Red_Normal_08px {
	// font-family: Arial, Helvetica, sans-serif;
	font-size: 8px;
	color: #c00;
}
.Arial_Red_Normal_08px a:link {
	color: #c00;
}
.Arial_Red_Normal_08px a:hover {
	color: #000000;
}
.Arial_Red_Normal_08px a:visited {
	color: #666666;
}
.Arial_Red_Normal_09px {
	// font-family: Arial, Helvetica, sans-serif;
	font-size: 9px;
	color: #c00;
}
.Arial_Red_Normal_09px a:link {
	color: #c00;
}
.Arial_Red_Normal_09px a:hover {
	color: #000000;
}
.Arial_Red_Normal_09px a:visited {
	color: #666666;
}
.Arial_Red_Normal_10px {
	// font-family: Arial, Helvetica, sans-serif;
	font-size: 10px;
	color: #c00;
}
.Arial_Red_Normal_10px a:link {
	color: #c00;
}
.Arial_Red_Normal_10px a:hover {
	color: #000000;
}
.Arial_Red_Normal_10px a:visited {
	color: #666666;
}
.Arial_Red_Normal_11px {
	// font-family: Arial, Helvetica, sans-serif;
	font-size: 11px;
	color: #c00;
}
.Arial_Red_Normal_11px a:link {
	color: #c00;
}
.Arial_Red_Normal_11px a:hover {
	color: #000000;
}
.Arial_Red_Normal_11px a:visited {
	color: #666666;
}
.Arial_Red_Normal_12px {
	// font-family: Arial, Helvetica, sans-serif;
	font-size: 12px;
	color: #c00;
}
.Arial_Red_Normal_12px a:link {
	color: #c00;
}
.Arial_Red_Normal_12px a:hover {
	color: #000000;
}
.Arial_Red_Normal_12px a:visited {
	color: #666666;
}
.Arial_Red_Normal_14px {
	// font-family: Arial, Helvetica, sans-serif;
	font-size: 14px;
	color: #c00;
}
.Arial_Red_Normal_14px a:link {
	color: #c00;
}
.Arial_Red_Normal_14px a:hover {
	color: #000000;
}
.Arial_Red_Normal_14px a:visited {
	color: #666666;
}
.Arial_Red_Normal_16px {
	// font-family: Arial, Helvetica, sans-serif;
	font-size: 16px;
	color: #c00;
}
.Arial_Red_Normal_16px a:link {
	color: #c00;
}
.Arial_Red_Normal_16px a:hover {
	color: #000000;
}
.Arial_Red_Normal_16px a:visited {
	color: #666666;
}
.Arial_Red_Bold_08px {
	// font-family: Arial, Helvetica, sans-serif;
	font-size: 8px;
	font-weight: bold;	color: #c00;
}
.Arial_Red_Bold_08px a:link {
	color: #c00;
}
.Arial_Red_Bold_08px a:hover {
	color: #000000;
}
.Arial_Red_Bold_08px a:visited {
	color: #666666;
}
.Arial_Red_Bold_09px {
	// font-family: Arial, Helvetica, sans-serif;
	font-size: 9px;
	font-weight: bold;	color: #c00;
}
.Arial_Red_Bold_09px a:link {
	color: #c00;
}
.Arial_Red_Bold_09px a:hover {
	color: #000000;
}
.Arial_Red_Bold_09px a:visited {
	color: #666666;
}
.Arial_Red_Bold_10px {
	// font-family: Arial, Helvetica, sans-serif;
	font-size: 10px;
	font-weight: bold;	color: #c00;
}
.Arial_Red_Bold_10px a:link {
	color: #c00;
}
.Arial_Red_Bold_10px a:hover {
	color: #000000;
}
.Arial_Red_Bold_10px a:visited {
	color: #666666;
}
.Arial_Red_Bold_11px {
	// font-family: Arial, Helvetica, sans-serif;
	font-size: 11px;
	font-weight: bold;	color: #c00;
}
.Arial_Red_Bold_11px a:link {
	color: #c00;
}
.Arial_Red_Bold_11px a:hover {
	color: #000000;
}
.Arial_Red_Bold_11px a:visited {
	color: #666666;
}
.Arial_Red_Bold_12px {
	// font-family: Arial, Helvetica, sans-serif;
	font-size: 12px;
	font-weight: bold;	color: #c00;
}
.Arial_Red_Bold_12px a:link {
	color: #c00;
}
.Arial_Red_Bold_12px a:hover {
	color: #000000;
}
.Arial_Red_Bold_12px a:visited {
	color: #666666;
}
.Arial_Red_Bold_14px {
	// font-family: Arial, Helvetica, sans-serif;
	font-size: 14px;
	font-weight: bold;	color: #c00;
}
.Arial_Red_Bold_14px a:link {
	color: #c00;
}
.Arial_Red_Bold_14px a:hover {
	color: #000000;
}
.Arial_Red_Bold_14px a:visited {
	color: #666666;
}
.Arial_Red_Bold_16px {
	// font-family: Arial, Helvetica, sans-serif;
	font-size: 16px;
	font-weight: bold;	color: #c00;
}
.Arial_Red_Bold_16px a:link {
	color: #c00;
}
.Arial_Red_Bold_16px a:hover {
	color: #000000;
}
.Arial_Red_Bold_16px a:visited {
	color: #666666;
}
.Arial_Red_Bold_24px {
	// font-family: Arial, Helvetica, sans-serif;
	font-size: 24px;
	font-weight: bold;
	color: #c00;
}
.Arial_Red_Bold_24px a:link {
	color: #c00;
}
.Arial_Red_Bold_24px a:hover {
	color: #000000;
}
.Arial_Red_Bold_24px a:visited {
	color: #666666;
}
.Arial_White_Normal_08px {
	// font-family: Arial, Helvetica, sans-serif;
	font-size: 8px;
	color: #FFFFFF;
}
.Arial_White_Normal_08px a:link {
	color: #FFFFFF;
}
.Arial_White_Normal_08px a:hover {
	color: #CCCCCC;
}
.Arial_White_Normal_08px a:visited {
	color: #CCCCCC;
}
.Arial_White_Normal_09px {
	// font-family: Arial, Helvetica, sans-serif;
	font-size: 9px;
	color: #FFFFFF;
}
.Arial_White_Normal_09px a:link {
	color: #FFFFFF;
}
.Arial_White_Normal_09px a:hover {
	color: #CCCCCC;
}
.Arial_White_Normal_09px a:visited {
	color: #CCCCCC;
}
.Arial_White_Normal_10px {
	// font-family: Arial, Helvetica, sans-serif;
	font-size: 10px;
	color: #FFFFFF;
}
.Arial_White_Normal_10px a:link {
	color: #FFFFFF;
}
.Arial_White_Normal_10px a:hover {
	color: #CCCCCC;
}
.Arial_White_Normal_10px a:visited {
	color: #CCCCCC;
}
.Arial_White_Normal_11px {
	// font-family: Arial, Helvetica, sans-serif;
	font-size: 11px;
	color: #FFFFFF;
}
.Arial_White_Normal_11px a:link {
	color: #FFFFFF;
}
.Arial_White_Normal_11px a:hover {
	color: #CCCCCC;
}
.Arial_White_Normal_11px a:visited {
	color: #CCCCCC;
}
.Arial_White_Normal_12px {
	// font-family: Arial, Helvetica, sans-serif;
	font-size: 12px;
	color: #FFFFFF;
}
.Arial_White_Normal_12px a:link {
	color: #FFFFFF;
}
.Arial_White_Normal_12px a:hover {
	color: #CCCCCC;
}
.Arial_White_Normal_12px a:visited {
	color: #CCCCCC;
}
.Arial_White_Normal_14px {
	// font-family: Arial, Helvetica, sans-serif;
	font-size: 14px;
	color: #FFFFFF;
}
.Arial_White_Normal_14px a:link {
	color: #FFFFFF;
}
.Arial_White_Normal_14px a:hover {
	color: #CCCCCC;
}
.Arial_White_Normal_14px a:visited {
	color: #CCCCCC;
}
.Arial_White_Normal_16px {
	// font-family: Arial, Helvetica, sans-serif;
	font-size: 16px;
	color: #FFFFFF;
}
.Arial_White_Normal_16px a:link {
	color: #FFFFFF;
}
.Arial_White_Normal_16px a:hover {
	color: #CCCCCC;
}
.Arial_White_Normal_16px a:visited {
	color: #CCCCCC;
}
.Arial_White_Bold_08px {
	// font-family: Arial, Helvetica, sans-serif;
	font-size: 8px;
	font-weight: bold;
	color: #FFFFFF;
}
.Arial_White_Bold_08px a:link {
	color: #FFFFFF;
}
.Arial_White_Bold_08px a:hover {
	color: #CCCCCC;
}
.Arial_White_Bold_08px a:visited {
	color: #CCCCCC;
}
.Arial_White_Bold_09px {
	// font-family: Arial, Helvetica, sans-serif;
	font-size: 9px;
	font-weight: bold;
	color: #FFFFFF;
}
.Arial_White_Bold_09px a:link {
	color: #FFFFFF;
}
.Arial_White_Bold_09px a:hover {
	color: #CCCCCC;
}
.Arial_White_Bold_09px a:visited {
	color: #CCCCCC;
}
.Arial_White_Bold_10px {
	// font-family: Arial, Helvetica, sans-serif;
	font-size: 10px;
	font-weight: bold;
	color: #FFFFFF;
}
.Arial_White_Bold_10px a:link {
	color: #FFFFFF;
}
.Arial_White_Bold_10px a:hover {
	color: #CCCCCC;
}
.Arial_White_Bold_10px a:visited {
	color: #CCCCCC;
}
.Arial_White_Bold_11px {
	// font-family: Arial, Helvetica, sans-serif;
	font-size: 11px;
	font-weight: bold;
	color: #FFFFFF;
}
.Arial_White_Bold_11px a:link {
	color: #FFFFFF;
}
.Arial_White_Bold_11px a:hover {
	color: #CCCCCC;
}
.Arial_White_Bold_11px a:visited {
	color: #CCCCCC;
}
.Arial_White_Bold_12px {
	// font-family: Arial, Helvetica, sans-serif;
	font-size: 12px;
	font-weight: bold;
	color: #FFFFFF;
}
.Arial_White_Bold_12px a:link {
	color: #FFFFFF;
}
.Arial_White_Bold_12px a:hover {
	color: #CCCCCC;
}
.Arial_White_Bold_12px a:visited {
	color: #CCCCCC;
}
.Arial_White_Bold_14px {
	// font-family: Arial, Helvetica, sans-serif;
	font-size: 14px;
	font-weight: bold;
	color: #FFFFFF;
}
.Arial_White_Bold_14px a:link {
	color: #FFFFFF;
}
.Arial_White_Bold_14px a:hover {
	color: #CCCCCC;
}
.Arial_White_Bold_14px a:visited {
	color: #CCCCCC;
}
.Arial_White_Bold_16px {
	// font-family: Arial, Helvetica, sans-serif;
	font-size: 16px;
	font-weight: bold;
	color: #FFFFFF;
}
.Arial_White_Bold_16px a:link {
	color: #FFFFFF;
}
.Arial_White_Bold_16px a:hover {
	color: #CCCCCC;
}
.Arial_White_Bold_16px a:visited {
	color: #CCCCCC;
}
.Arial_Blue_Normal_08px {
	// font-family: Arial, Helvetica, sans-serif;
	font-size: 8px;
	color: #193F9B;
}
.Arial_Blue_Normal_08px a:link {
	color: #193F9B;
}
.Arial_Blue_Normal_08px a:hover {
	color: #375BAB;
}
.Arial_Blue_Normal_08px a:visited {
	color: #375BAB;
}
.Arial_Blue_Normal_09px {
	// font-family: Arial, Helvetica, sans-serif;
	font-size: 9px;
	color: #193F9B;
}
.Arial_Blue_Normal_09px a:link {
	color: #193F9B;
}
.Arial_Blue_Normal_09px a:hover {
	color: #375BAB;
}
.Arial_Blue_Normal_09px a:visited {
	color: #375BAB;
}
.Arial_Blue_Normal_10px {
	// font-family: Arial, Helvetica, sans-serif;
	font-size: 10px;
	color: #193F9B;
}
.Arial_Blue_Normal_10px a:link {
	color: #193F9B;
}
.Arial_Blue_Normal_10px a:hover {
	color: #375BAB;
}
.Arial_Blue_Normal_10px a:visited {
	color: #375BAB;
}
.Arial_Blue_Normal_11px {
	// font-family: Arial, Helvetica, sans-serif;
	font-size: 11px;
	color: #193F9B;
}
.Arial_Blue_Normal_11px a:link {
	color: #193F9B;
}
.Arial_Blue_Normal_11px a:hover {
	color: #375BAB;
}
.Arial_Blue_Normal_11px a:visited {
	color: #375BAB;
}
.Arial_Blue_Normal_12px {
	// font-family: Arial, Helvetica, sans-serif;
	font-size: 12px;
	color: #193F9B;
}
.Arial_Blue_Normal_12px a:link {
	color: #193F9B;
}
.Arial_Blue_Normal_12px a:hover {
	color: #375BAB;
}
.Arial_Blue_Normal_12px a:visited {
	color: #375BAB;
}
.Arial_Blue_Normal_14px {
	// font-family: Arial, Helvetica, sans-serif;
	font-size: 14px;
	color: #193F9B;
}
.Arial_Blue_Normal_14px a:link {
	color: #193F9B;
}
.Arial_Blue_Normal_14px a:hover {
	color: #375BAB;
}
.Arial_Blue_Normal_14px a:visited {
	color: #375BAB;
}
.Arial_Blue_Normal_16px {
	// font-family: Arial, Helvetica, sans-serif;
	font-size: 16px;
	color: #193F9B;
}
.Arial_Blue_Normal_16px a:link {
	color: #193F9B;
}
.Arial_Blue_Normal_16px a:hover {
	color: #375BAB;
}
.Arial_Blue_Normal_16px a:visited {
	color: #375BAB;
}
.Arial_Blue_Bold_08px {
	// font-family: Arial, Helvetica, sans-serif;
	font-size: 8px;
	font-weight: bold;
	color: #193F9B;
}
.Arial_Blue_Bold_08px a:link {
	color: #193F9B;
}
.Arial_Blue_Bold_08px a:hover {
	color: #375BAB;
}
.Arial_Blue_Bold_08px a:visited {
	color: #375BAB;
}
.Arial_Blue_Bold_09px {
	// font-family: Arial, Helvetica, sans-serif;
	font-size: 9px;
	font-weight: bold;
	color: #193F9B;
}
.Arial_Blue_Bold_09px a:link {
	color: #193F9B;
}
.Arial_Blue_Bold_09px a:hover {
	color: #375BAB;
}
.Arial_Blue_Bold_09px a:visited {
	color: #375BAB;
}
.Arial_Blue_Bold_10px {
	// font-family: Arial, Helvetica, sans-serif;
	font-size: 10px;
	font-weight: bold;
	color: #193F9B;
}
.Arial_Blue_Bold_10px a:link {
	color: #193F9B;
}
.Arial_Blue_Bold_10px a:hover {
	color: #375BAB;
}
.Arial_Blue_Bold_10px a:visited {
	color: #375BAB;
}
.Arial_Blue_Bold_11px {
	// font-family: Arial, Helvetica, sans-serif;
	font-size: 11px;
	font-weight: bold;
	color: #193F9B;
}
.Arial_Blue_Bold_11px a:link {
	color: #193F9B;
}
.Arial_Blue_Bold_11px a:hover {
	color: #375BAB;
}
.Arial_Blue_Bold_11px a:visited {
	color: #375BAB;
}
.Arial_Blue_Bold_12px {
	// font-family: Arial, Helvetica, sans-serif;
	font-size: 12px;
	font-weight: bold;
	color: #193F9B;
}
.Arial_Blue_Bold_12px a:link {
	color: #193F9B;
}
.Arial_Blue_Bold_12px a:hover {
	color: #375BAB;
}
.Arial_Blue_Bold_12px a:visited {
	color: #375BAB;
}
.Arial_Blue_Bold_14px {
	// font-family: Arial, Helvetica, sans-serif;
	font-size: 14px;
	font-weight: bold;
	color: #193F9B;
}
.Arial_Blue_Bold_14px a:link {
	color: #193F9B;
}
.Arial_Blue_Bold_14px a:hover {
	color: #375BAB;
}
.Arial_Blue_Bold_14px a:visited {
	color: #375BAB;
}
.Arial_Blue_Bold_16px {
	// font-family: Arial, Helvetica, sans-serif;
	font-size: 16px;
	font-weight: bold;
	color: #193F9B;
}
.Arial_Blue_Bold_16px a:link {
	color: #193F9B;
}
.Arial_Blue_Bold_16px a:hover {
	color: #375BAB;
}
.Arial_Blue_Bold_16px a:visited {
	color: #375BAB;
}
.Arial_Blue_Bold_24px {
	// font-family: Arial, Helvetica, sans-serif;
	font-size: 24px;
	font-weight: bold;
	color: #193F9B;
}
.Arial_Blue_Bold_24px a:link {
	color: #193F9B;
}
.Arial_Blue_Bold_24px a:hover {
	color: #375BAB;
}
.Arial_Blue_Bold_24px a:visited {
	color: #375BAB;
}
.Title {
	// font-family: Arial, Helvetica, sans-serif;
	font-size: 16px;
	font-weight: bold;
	color: #c00;
}
.NormalCaption {
	// font-family: Arial, Helvetica, sans-serif;
	font-size: 12px;
	font-weight: bold;
	color: #000000;
}
.Normal {
	// font-family: Arial, Helvetica, sans-serif;
	font-size: 12px;
}
.NormalCaptionM {
	// font-family: Arial, Helvetica, sans-serif;
	font-size: 12px;
	font-weight: bold;
}
.disabled
{
	// font-family: Arial, Helvetica, sans-serif;
	font-size: 12px;
	background-color: #CCC;
}
fieldset.flash
{
	width: 375px;
	margin: 10px 0px 10px 0px;
	border-color: #D9E4FF;
}

.progressWrapper {
	width: 357px;
	overflow: hidden;
}
.progressContainer {
	margin: 5px;
	padding: 4px;
	
	border: solid 1px #E8E8E8;
	background-color: #F7F7F7;
	
	overflow: hidden;
}
.red /* Error */
{
	border: solid 2px #B50000;
	background-color: #FFEBEB;
}
.green /* Current */ 
{
	border: solid 2px #605F5F;
	background-color: #FFFFFF;
}
.blue /* Complete */
{
	border: solid 2px #605F5F;
	background-color: #FFFFFF;
}
.progressName {
	// font-family: Arial, Helvetica, sans-serif;
	font-size: 11px;
	font-weight: bold;
	color: #000000;
	
	width: 323px;
	height: 14px;
	text-align: left;
	white-space: nowrap;
	overflow: hidden;
}

.progressBarFull
{
	width: 100%;
	height: 4px;
	background-color: #EBEBE9;
	padding: 1px 1px 0px 1px;
}

.progressBarInProgress,
.progressBarComplete,
.progressBarError {
	font-size: 0px;
	width: 0%;
	height: 2px;
	background-color: #0639FA;
	margin-top: 0px;
}
.progressBarComplete {
	width: 100%;
	background-color: green;
	visibility: hidden;
}
.progressBarError {
	width: 100%;
	background-color: red;
	visibility: hidden;
}
.progressBarStatus {
	margin-top: 2px;
	width: 337px;
	// font-family: Arial, Helvetica, sans-serif;
	font-size: 10px;
	text-align: left;
	white-space: nowrap;
}
a.progressCancel,
a.progressCancel:link,
a.progressCancel:active,
a.progressCancel:visited,
a.progressCancel:hover
{
	font-size: 0px;
	display: block;
	height: 14px;
	width: 14px;
	
	/* background-image: url(../images/cancelbutton.gif); */
	background-repeat: no-repeat;
	background-position: -14px 0px;
	float: right;
}
a.progressCancel:hover 
{
	background-position: 0px 0px;
}
#floating_link {
     position: fixed;
		 z-index: 5;
     right: 0;
     top: 276px;
     width: 34px;
     height: 126px;
     overflow: hidden;
}
.needhelp {
	outline: none; 
}
.smiley
{
	left: 0px;
	top: 0px;
	width: 16px;
	height: 16px;
}
progress {
	-webkit-appearance: none;
}
progress::-webkit-progress-bar {
  background: #e6e6e6;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2) inset;
}
progress::-webkit-progress-value {
  background: #0000ff;
}	
progress::-moz-progress-bar {
  background: #0000ff;
}
progress::-ms-fill { 
	background: #0000ff;
}

